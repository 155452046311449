<template>
  <div class="container">
    <div class="content">
      <my-icon fontSize="128" iconName="icon-wangluojiance_wuwang_128_hong"></my-icon>
      <div class='fin_style'>{{$t('login.loginFail')}}</div>
      <div class='back_style'>{{$t('login.changeMobile')}}
        <span @click='clickRegister'>{{$t('login.newLogin')}}</span>
      </div>
      <Back class='back_length' :type='goType'></Back>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  export default {
    components: {
      Back
    },
    data() {
      return {
        goType:'error',
      }
    },
    methods: {
      clickRegister() {
        this.$router.push({
          name: 'register'
        })
      },
    }
  }

</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;

    .content {
      width: 80%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .fin_style {
        font-size: 32px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .back_style {
        font-size: 24px;
        color: #999999;
        margin-bottom: 48px;

        span {
          color: #1AB370;
        }
      }

      .back_length {
        margin: auto;
        width: 56%;
      }
    }
  }

</style>
